export const environment: any = {
  production: true,
  staging: false,
  apiUrl: 'https://api.irfcg.me/v1',
  baseApiUrl: 'https://api.irfcg.me',
  websiteUrl: 'https://phpstack-1308396-4948393.cloudwaysapps.com',
  mediaUrl:'https://storage.medialib.dev/media/',
  recaptchaSiteKey: '6Lenh9QgAAAAAIeTAy1EIF8qN8H8-SyQgH9TCw5N',
  requiredFormsUrl: 'https://irfcg.me/clanak/potrebna-dokumentacija'
};
